<template>
    <div class="goods">
        <div class="goods-info">
            <img class="img" :src="img_url"/>
            <div class="content">
                <div class="description">{{ description }}</div>
                <div class="rule">
                    <rsck-select :hide-icon="true" :text="showText" :active="true"></rsck-select>
                </div>
                <div class="warning" v-if="warning">
                    {{warning}}
                </div>
                <div class="text">
                    {{ text }}
                </div>
            </div>
        </div>
        <div class="goods-extends">
            <slot></slot>
        </div>

    </div>
</template>

<script>
  import RsckSelect from '@/components/common/rsck-select'
  import RsckRadio from '@/components/common/rsck-radio'

  export default {
    name: 'CouponGoodsV2',
    components: {RsckRadio, RsckSelect},
    props: {
      img_url: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => {
          return [
            {label: '选项一', value: 0},
            {label: '选项一', value: 1},
            {label: '选项二', value: 2},
          ]
        }
      },
      selectOptions: {
        type: [String, Number],
        default: 0
      },
      showText: {
        type: String,
        default: '自提点取货'
      },
      warning:{
        type:String,
        default:''
      }
    },
    mounted () {
      this.getType = this.$props.selectOptions
    },
    data () {
      return {
        show: false,
      }
    },
    methods: {
      onRuleClick () {
        this.$emit('onRuleClick', this.$props.param)
      },
      onSelect () {
        this.$emit('onSelect')
      },

    },
  }
</script>

<style scoped lang="scss">
    .goods {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        .goods-info {
            width: 100%;
            display: flex;

            .img {
                width: 109px;
                height: 90px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                border-radius: 8px;
                margin-right: 12px;
            }

            .content {
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
                display: flex;

                .description {
                    height: 15px;
                    font-size: 14px;
                    color: #333333;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    word-break: break-all;
                }

                .rule {
                    width: 100%;
                }

                .warning {
                    height: 24px;
                    color: #F46A17;
                    font-size: 12px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                .text {
                    width: 100%;
                    font-size: 14px;
                    color: #C10000;
                    font-weight: bold;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }
        }

        .goods-extends {
            width: 100%;
        }

        .popup-window {
            padding: 30px;
        }

        .radio-line {
            width: 100%;
            margin-bottom: 10px;
        }

        .radio-text {
            color: #333;
            font-size: 12px;
        }
    }
</style>