<template>
  <div class="formV1"><slot /></div>
</template>
<script>
/**
 * form 表单
 * @description 此组件一般用于表单场景，可以配置Input输入框，Select弹出框，进行表单验证等。
 * @property {Object} model 表单数据对象
 * @property {Object} rules 通过ref设置，见官网说明
 * @property {Array} error-type 错误的提示方式，数组形式，见上方说明(默认['message'])
 * @example <formV1 :model="form" ref="formV1"></formV1>
 */

export default {
  name: "formV1",
  props: {
    // 当前form的需要验证字段的集合
    model: {
      type: Object,
      default() {
        return {};
      },
    },
    errorType: {
      type: Array,
      default() {
        return ["message", "toast"];
      },
    },
  },
  provide() {
    return {
      formV1: this,
    };
  },
  data() {
    return {
      rules: {},
    };
  },
  created() {
    // 存储当前form下的所有formitem的实例
    this.fields = [];
  },
  methods: {
    setRules(rules) {
      this.rules = rules;
    },
    // 清空所有formitem组件的内容，本质上是调用了formitem组件中的resetField()方法
    resetFields() {
      this.fields.map((field) => {
        field.resetField();
      });
    },
    // 校验全部数据
    validate(callback) {
      return new Promise((resolve) => {
        // 对所有的formitem进行校验
        let valid = true; // 默认通过
        let count = 0; // 用于标记是否检查完毕
        let errorArr = []; // 存放错误信息
        this.fields.map((field) => {
          // 调用每一个formitem实例的validation的校验方法
          field.validation("", (error) => {
            // 如果任意一个formitem校验不通过，就意味着整个表单不通过
            if (error) {
              valid = false;
              errorArr.push(error);
            }
            // 当历遍了所有的formitem时，调用promise的then方法
            if (++count === this.fields.length) {
              resolve(valid); // 进入promise的then方法
              // 判断是否设置了toast的提示方式，只提示最前面的表单域的第一个错误信息
              if (
                this.errorType.indexOf("none") === -1 &&
                this.errorType.indexOf("toast") >= 0 &&
                errorArr.length
              ) {
                this.$toast.text(errorArr[0], {
                  duration: 1000,
                });
              }
              // 调用回调方法
              if (typeof callback == "function") callback(valid);
            }
          });
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@include b(formV1) {
  width: 100%;
  height: 100%;
}
</style>
