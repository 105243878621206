<template>
    <div :class="[active ? 'active' : 'default']" class="select-container" @click="select">
        {{text}}
        <div class="icon-container">
            <img v-if="!hideIcon" src="https://api.mengjingloulan.com/storage/wechat/new-user-center/down.png"/>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'rsck-select',
    props: {
      text: {
        type: String,
        default: '领取方式'
      },
      active: {
        type: Boolean,
        default: false
      },
      hideIcon: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      select () {
        this.$emit('onSelect')
      }
    }
  }
</script>

<style scoped lang="scss">
    .select-container {
        font-size: 12px;
        color: #A2A2A2;
        background: #F6F6F6;
        display: inline-flex;
        align-items: center;
        padding: 2px 5px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;


        .icon-container{
            flex: 1;
            display: flex;
            justify-content: flex-end;

            img{
                margin-left: 5px;
                width: 10px;
                height:10px;
            }
        }


    }

    .default {
        color: #A2A2A2 !important;
    }

    .active {
        color: #333333 !important;
    }
</style>