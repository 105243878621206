<template>
    <div class="coupon-card-get-v1-container">
        <cell-v3 class="receive-user" :hide-icon="true" :hide-action="true">
            <div slot="title" class="user-info">
                <div>{{address.real_name}}</div>
                <div class="mobile">{{address.mobile}}</div>
            </div>
        </cell-v3>
        <cell-v3 class="express-info border-bottom" :hide-icon="true">
            <div slot="title" class="title">
                <div class="zitidian">{{address.province}} {{address.city}} {{address.town}} {{address.address}}</div>
            </div>
            <div class="actions">
                <div class="button" @click="chooseAddress">修改地址</div>
            </div>
        </cell-v3>
        <div class="goods-list" v-if="showResult">
            <div class="goods-header">{{title}}</div>
            <div :class="[item.is_checked == false ? 'disabled' :'']" v-for="(item,index) in finalDataList"
                 :key="index">
                <coupon-goods-v2
                        :img_url="item.cover"
                        :description="item.title"
                        :options="item.options"
                        :selectOptions="item.selectOption"
                        class="goods border-bottom"
                        show-text="快递发送/配送上门"
                        :text="'价值'+item.market_price+'元'"
                        :warning="item.tip"
                >
                    <div class="goods-extend">
                        <template v-if="item.max_get > 1">
                            <div class="goods-extend-line">
                                <div class="label">领取数量</div>
                                <nut-textinput
                                        v-model="item.get_number"
                                        placeholder="本次领取数量"
                                        :clearBtn="false"
                                        class="inputs"
                                        @blur="onBlur"
                                />
                            </div>
                            <div class="goods-extend-line">
                                <cell-v3 class="warning" :hide-icon="true" :hide-title="true">
                                    可以修改领取数量，本次最多可以领取{{item.max_get}}
                                </cell-v3>
                            </div>
                        </template>
                        <div class="goods-extend-line">
                            <div class="label">订单备注</div>
                            <nut-textinput
                                    v-model="item.remark"
                                    placeholder="请输入您的备注"
                                    :clearBtn="false"
                                    class="inputs"
                                    @blur="onBlur"
                            />
                        </div>
                        <div class="goods-extend-line" v-if="item.return_exchange == 1">
                            <cell-v3 class="warning" :hide-icon="true" :hide-title="true">提示：该商品不可退换</cell-v3>
                        </div>
                    </div>
                </coupon-goods-v2>
            </div>
        </div>
    </div>
</template>

<script>
  import CellV3 from '@/template/community/cell/cellV3'
  import CouponGoodsV2 from '@/template/coupon-card/CouponGoodsV2'
  import RsckSelect from '@/components/common/rsck-select'

  export default {
    name: 'CouponCardGetV2',
    components: {RsckSelect, CouponGoodsV2, CellV3},
    props: {
      lists: {
        type: Array,
        default () {
          return []
        },
      },
      title: {
        type: String,
        default: ''
      },
      address: {
        type: Object,
        default: () => {
          return {}
        }
      },
      cardId: {
        type: [String, Number],
        default: 0
      }
    },
    mounted () {
      //计算标题可以使用的长度
      this.$props.lists.forEach(item => {
        item.checked = false
        item.selectOption = 0
        item.remark = this.getCache(this.$props.cardId, item.goods_id)
        item.get_number = 1
        console.log('item',item)
        this.finalDataList.push(item)
      })
      this.showResult = true
      this.$forceUpdate()
    },
    data () {
      return {
        finalDataList: [],
        showResult: false,
      }
    },
    methods: {
      chooseAddress () {
        this.$emit('chooseAddress')
      },
      getGoodsList () {
        return this.finalDataList
      },
      setCache (card_id, goods_id, remark = '') {
        let name = 'Remark_' + card_id + '_' + goods_id
        localStorage.setItem(name, remark)
      },
      getCache (card_id, goods_id) {
        let name = 'Remark_' + card_id + '_' + goods_id
        let remark = localStorage.getItem(name)
        return remark == null ? '' : remark
      },
      onBlur (e) {
        this.finalDataList.forEach(item => {
          this.setCache(this.$props.cardId, item.goods_id, item.remark)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .coupon-card-get-v1-container {
        display: flex;
        flex-wrap: wrap;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        background: #fff;
        padding: 15px;

        .border-bottom {
            border-bottom: #F6f6f6 solid 1px;
        }

        .receive-user {
            display: flex;
            align-items: center;
            padding-bottom: 12px;

            .user-info {
                font-size: 14px;
                font-weight: bold;
                color: #333;
                display: flex;
                align-items: center;

                .mobile {
                    display: flex;
                    align-items: center;
                    color: #939393;
                    font-weight: 100;
                    margin-left: 7px;
                }
            }
        }

        .site-point {
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            padding-top: 12px;

            .title {
                flex: 1;
                display: flex;
                align-items: center;

                .zitidian {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333;
                }

                .site-point-name {
                    color: #333333;
                    font-size: 14px;
                }
            }

        }

        .address {
            padding-bottom: 12px;
            .title {
                padding-left: 56px;
                font-size: 12px;
                color: #939393;
                line-height: 18px;
            }
        }

        .goods-list {
            padding: 15px 0 0 0;

            .goods-header {
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                margin-bottom: 14px;
            }

            .goods {
                margin-bottom: 20px;

                .goods-extend {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 15px 0 10px 0;

                    .goods-extend-line {
                        width: 100%;
                        display: flex;
                        margin-bottom: 10px;

                        .label {
                            width: 109px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            color: #333333;
                            font-size: 12px;
                            margin-right: 12px;
                        }

                        .select-class {
                            flex: 1;
                            height: 20px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            border-radius: 4px;
                        }

                        .warning {
                            color: #DF8621;
                            font-size: 12px;
                        }

                        /deep/ .nut-textinput {
                            flex: 1 !important;
                        }

                        /deep/ .nut-textinput input {
                            border: none;
                            color: #939393;
                            padding: 0 !important;
                            height: 24px;
                            font-size: 12px;
                            font-weight: 100;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .actions {
            font-size: 14px;
            color: #F46A17;
            display: flex;
            align-items: center;

            img {
                width: 16px;
                height: 16px;
            }

            .button {
                color: #333333;
                font-size: 12px;
                border: #D1D1D1 solid 1px;
                width: 84px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-border-radius: 36px;
                -moz-border-radius: 36px;
                border-radius: 36px;
                margin-left: 20px;
            }
        }

        .express-info {
            margin-top: -5px;
            padding: 0 0 15px 0;
            .title {
                font-size: 12px;
                color: #A2A2A2;
            }
        }

    }
</style>