<template>
    <div class="card-get-container">
        <template v-if="showResult">
            <coupon-card-get-v1 ref="v1" v-if="showSitePointGoods" :lists="sitePointGoods"
                                :title="cardInfo.card_name" :cardId="cardInfo.id"
            ></coupon-card-get-v1>
            <coupon-card-get-v2 ref="v2" v-if="showExpressGoods" class="express-get" :lists="expressGoods"
                                :title="cardInfo.card_name" @chooseAddress="chooseAddress"
                                :address="express.express" :cardId="cardInfo.id"
            ></coupon-card-get-v2>
            <coupon-card-get-v3 ref="v3" v-if="showCardGoods" class="express-get" :lists="cardGoods"
                                :title="cardInfo.card_name" :cardId="cardInfo.id"
            ></coupon-card-get-v3>
            <div class="fee">
                <cell-v3 :hide-icon="true">
                    <div slot="title" class="title">运费</div>
                    <div class="price">¥{{shippingFee}}</div>
                </cell-v3>
            </div>
            <div class="page-space">

            </div>

        </template>

        <div class="page-bottom">
            <cell-v3 :hide-icon="true">
                <div slot="title" class="title">共{{goodsTotalNumber}}件</div>
                <div class="actions">
                    <div class="price">合计: ¥{{shippingFee}}</div>
                    <div class="button" @click="doGet">领取</div>
                </div>
            </cell-v3>
        </div>

        <loading-v1 ref="loading"></loading-v1>
        <rsck-loading text="数据提交中,请稍后" ref="rsckLoading"></rsck-loading>
    </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CouponCardGetV1 from '@/template/coupon-card/coupon-card-get-V1'
  import CouponCardGetV2 from '@/template/coupon-card/coupon-card-get-V2'
  import CellV3 from '@/template/community/cell/cellV3'
  import LoadingV1 from '@/template/community/loading/loadingV1'
  import CouponCardGetV3 from '@/template/coupon-card/coupon-card-get-V3'
  import RsckLoading from '@/components/common/rsck-loading'

  export default {
    name: 'card-get',
    needVuex: true,
    configVuex: ['coupon_card_card', 'community_order'],
    components: {RsckLoading, CouponCardGetV3, LoadingV1, CellV3, CouponCardGetV2, CouponCardGetV1},
    mounted () {
      document.title = '礼品卡领取'
      this.query = this.$route.query
      this.init(this.query)
      this.getCardApplyView()
      this.canContinue = true
    },
    computed: {
      ...mapState('coupon_card_card', [
        'cardId',
        'goodsId',
        'express'
      ]),
      goodsTotalNumber () {
        let cardGoodsNumber = 0
        let sitePointGoodsNumber = 0
        let expressGoodsNumber = 0
        this.cardGoods.forEach(item => {
          cardGoodsNumber += 1
        })
        this.expressGoods.forEach(item => {
          expressGoodsNumber += 1
        })
        this.sitePointGoods.forEach(item => {
          sitePointGoodsNumber += 1
        })

        let number = parseInt(cardGoodsNumber) + parseInt(sitePointGoodsNumber) + parseInt(expressGoodsNumber)
        return number
      },
    },
    data () {
      return {
        canContinue: false,
        query: {
          card_id: '',
          goods_id: '',
          express: {
            express: {}
          }
        },
        cardGoods: [],//以卡的形式在线领取的商品
        showCardGoods: false,//是否显示在线领取的商品
        expressGoods: [],//快递的形式领取的商品
        showExpressGoods: false,//是否显示已快递形式领取的商品
        sitePointGoods: [],//已自提点的形式领取的商品
        showSitePointGoods: false,//是否显示已自提点形式领取的商品
        cardInfo: {},//卡的信息
        showResult: false,
        shippingFee: 0,
        loading: false,
        scrollHeight: window.innerHeight
      }
    },
    methods: {
      ...mapActions('coupon_card_card', [
        'init',
      ]),
      onRefresh (done) {
        this.goodsList = []
        this.params = []
        this.getCardApplyView().finally(() => {
          done()
        })
      },
      getCardApplyView () {
        this.$refs.loading.show()
        let query = {
          card_id: this.cardId,
          goods_id: this.goodsId
        }
        this.$api.couponCard.getCouponCardUseView(query).then(response => {
          let data = response.data
          this.shippingFee = data.price
          if (typeof data.card_list != 'undefined') {
            let cardGoodsTemp = []
            let cardGoods = data.card_list
            cardGoods.forEach(item => {
              let temp = {}
              temp.title = item.goodInfo.title
              temp.goods_id = item.goods_id
              temp.cover = item.goodInfo.cover_path
              temp.number = item.claim_number
              temp.per_number = item.number
              temp.market_price = item.goodInfo.market_price
              temp.options = item.select_mail_type
              temp.select_option = item.mail_type
              temp.tip = item.tip
              temp.shipping_fee = item.shipping_fee
              temp.status = item.status
              temp.return_exchange = item.goodInfo.return_exchange
              temp.max_get = item.goodInfo.max_get
              cardGoodsTemp.push(temp)
            })
            this.cardGoods = cardGoodsTemp
            if (this.cardGoods.length > 0) {
              this.showCardGoods = true
            }
          }
          if (typeof data.express != 'undefined') {
            let expressGoodsTemp = []
            let expressGoods = data.express
            expressGoods.forEach(item => {
              let temp = {}
              temp.title = item.goodInfo.title
              temp.goods_id = item.goods_id
              temp.cover = item.goodInfo.cover_path
              temp.number = item.claim_number
              temp.per_number = item.number
              temp.market_price = item.goodInfo.market_price
              temp.options = item.select_mail_type
              temp.select_option = item.mail_type
              temp.tip = item.tip
              temp.shipping_fee = item.shipping_fee
              temp.status = item.status
              temp.return_exchange = item.goodInfo.return_exchange
              temp.max_get = item.goodInfo.max_get
              expressGoodsTemp.push(temp)

            })
            this.expressGoods = expressGoodsTemp
            if (this.expressGoods.length > 0) {
              this.showExpressGoods = true
            }
          }
          if (typeof data.site_point != 'undefined') {
            let sitePointGoodsTemp = []
            let sitePointGoods = data.site_point
            sitePointGoods.forEach(item => {
              let temp = {}
              temp.title = item.goodInfo.title
              temp.goods_id = item.goods_id
              temp.cover = item.goodInfo.cover_path
              temp.number = item.claim_number
              temp.per_number = item.number
              temp.market_price = item.goodInfo.market_price
              temp.options = item.select_mail_type
              temp.select_option = item.mail_type
              temp.tip = item.tip
              temp.shipping_fee = item.shipping_fee
              temp.status = item.status
              temp.return_exchange = item.goodInfo.return_exchange
              temp.max_get = item.goodInfo.max_get
              sitePointGoodsTemp.push(temp)
            })
            this.sitePointGoods = sitePointGoodsTemp
            if (this.sitePointGoods.length > 0) {
              this.showSitePointGoods = true
            }
          }
          //将接口返回的快递信息写入存储

          if (data.express_site_point.site_name && !this.express.site_point.real_name) {
            this.$vuex.coupon_card_card(
              'express.site_point.real_name',
              data.express_site_point.real_name
            )
          }

          if (data.express_site_point.mobile && !this.express.site_point.mobile) {
            this.$vuex.coupon_card_card(
              'express.site_point.mobile',
              data.express_site_point.mobile
            )
          }

          if (data.express_site_point.site_name && !this.express.site_point.site_name) {
            this.$vuex.coupon_card_card(
              'express.site_point.site_name',
              data.express_site_point.site_name
            )
          }

          if (data.express_site_point.address && !this.express.site_point.address) {
            this.$vuex.coupon_card_card(
              'express.site_point.address',
              data.express_site_point.address
            )
          }

          if (data.express_site_point.site_point_id && !this.express.site_point.site_point_id) {
            this.$vuex.coupon_card_card(
              'express.site_point.site_point_id',
              data.express_site_point.site_point_id
            )
          }

          if (data.express_address.address && !this.express.express.address) {
            this.$vuex.coupon_card_card(
              'express.express.address',
              data.express_address.address
            )
          }

          if (data.express_address.address_id && !this.express.express.address_id) {
            this.$vuex.coupon_card_card(
              'express.express.address_id',
              data.express_address.address_id
            )
          }

          if (data.express_address.city && !this.express.express.city) {
            this.$vuex.coupon_card_card(
              'express.express.city',
              data.express_address.city
            )
          }

          if (data.express_address.mobile && !this.express.express.mobile) {
            this.$vuex.coupon_card_card(
              'express.express.mobile',
              data.express_address.mobile
            )
          }

          if (data.express_address.province && !this.express.express.province) {
            this.$vuex.coupon_card_card(
              'express.express.province',
              data.express_address.province
            )
          }

          if (data.express_address.real_name && !this.express.express.real_name) {
            this.$vuex.coupon_card_card(
              'express.express.real_name',
              data.express_address.real_name
            )
          }

          if (data.express_address.town && !this.express.express.town) {
            this.$vuex.coupon_card_card(
              'express.express.town',
              data.express_address.town
            )
          }

          this.cardInfo = data.basicInfo
          this.showResult = true
          this.$refs.loading.hide()
        })
      },
      chooseAddress () {
        let query = {
          ...this.query,
          express: {
            express: {}
          }
        }
        query.express.express = this.express.express
        query = encodeURIComponent(JSON.stringify(query))
        if (this.canContinue) {
          this.canContinue = false

          this.$platform.wxsdk.wxRoute({
            type: 'redirectTo',
            url: '/web/address/list?backType=2&query=' + query,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      doGet () {
        //组合卡片领取参数
        let data = {
          card_id: this.query.card_id,
          site_point_id: this.express.site_point.site_point_id,
          address_id: this.express.express.address_id,
          real_name: this.express.site_point.real_name,
          real_mobile: this.express.site_point.mobile,
          goods_id: {}
        }

        let goods = []
        if (typeof this.$refs.v1 != 'undefined') {
          let goodsV1 = this.$refs.v1.getGoodsList()

          goodsV1.forEach(item => {
            if(item.get_number > item.max_get){
              this.$toast.warn(item.title + '最多领取'+item.max_get)
              throw new Error(item.title + '最多领取'+item.max_get)
            }
            goods.push({
              goods_id: item.goods_id,
              get_number:item?.get_number || 1,
              type: 1,
              remark: item.remark
            })
          })
        }

        if (typeof this.$refs.v2 != 'undefined') {
          let goodsV2 = this.$refs.v2.getGoodsList()

          goodsV2.forEach(item => {
            if(item.get_number > item.max_get){
              this.$toast.warn(item.title + '最多领取'+item.max_get)
              throw new Error(item.title + '最多领取'+item.max_get)
            }
            goods.push({
              goods_id: item.goods_id,
              get_number:item?.get_number || 1,
              type: 2,
              remark: item.remark
            })
          })
        }

        if (typeof this.$refs.v3 != 'undefined') {
          let goodsV3 = this.$refs.v3.getGoodsList()
          goodsV3.forEach(item => {
            if(item.get_number > item.max_get){
              this.$toast.warn(item.title + '最多领取'+item.max_get)
              throw new Error(item.title + '最多领取'+item.max_get)
            }
            goods.push({
              goods_id: item.goods_id,
              get_number:item?.get_number || 1,
              type: -1,
              remark: item.remark
            })
          })
        }

        //对数据进行必要的判断
        if (!data.card_id) {
          this.$toast.warn('参数非法')
          return
        }

        goods.forEach(item => {
          if (item.type == 1) {
            if (!data.site_point_id) {
              this.$toast.warn('请选择自提点')
              throw new Error('请选择自提点')
            }

            if (!data.real_name) {
              this.$toast.warn('请设置提货人姓名')
              throw new Error('请设置提货人姓名')
            }

            if (!data.real_mobile) {
              this.$toast.warn('请设置提货人联系电话')
              throw new Error('请设置提货人联系电话')
            }
          } else if (item.type == 2) {
            if (!data.address_id) {
              this.$toast.warn('请选择您的收货地址')
              throw new Error('请选择您的收货地址')
            }
          }
        })

        this.$refs.rsckLoading.show()
        data.goods_id = goods

        if (!this.loading) {
          this.$api.couponCard.createCouponGetOrder(data).then(response => {
            this.removeRemark(goods)
            this.$refs.rsckLoading.hide()
            this.loading = false
            if (typeof response.data.status == 'undefined') {
              //需要支付
              let params = {
                ...response.data,
                successTitle: '领取成功',
                successText: '恭喜您，礼品领取成功',
                card_id: this.cardId,
                goods_id: this.goodsId
              }
              let options = encodeURIComponent(JSON.stringify(params))
              this.$platform.wxsdk.wxRoute({
                type: 'redirectTo',
                url: '/web/money/coupon-card-express-fee-pay?query=' + options,
              })
            } else {
              //不需要支付，跳转到领取成功的界面
              let params = {
                title: '领取成功',
                text: '恭喜您！礼品领取成功！',
                card_id: this.query.card_id,
                // backType: 2
                backType: 1
              }

              let options = encodeURIComponent(JSON.stringify(params))
              this.$platform.wxsdk.wxRoute({
                type: 'redirectTo',
                url: '/web/couponCard/success-page?query=' + options,
              })
            }
          }).catch(error => {
            this.$refs.rsckLoading.hide()
            this.$toast.fail(error.message)
            this.loading = false
          })
        }
      },
      removeRemark(goods){
        goods.forEach( item => {
          let name = 'Remark_' + this.query.card_id + '_' + item.goods_id
          localStorage.removeItem(name)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-get-container {
        width: 100vw;
        min-height: 100vh;
        background: #f6f6f6;
        position: relative;
        box-sizing: border-box;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .express-get {
            margin-top: 10px;
        }

        .fee {
            background: #fff;
            padding: 15px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            margin-top: 10px;

            .title {
                color: #333333;
                font-size: 14px;
            }

            .price {
                color: #333333;
                font-size: 14px;
                font-weight: bold;
            }
        }

        .page-space {
            width: 100%;
            height: 66px;
        }

        .page-bottom {
            background-color: #fff;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            border-top: #f6f6f6 solid 1px;
            height: 56px;
            z-index: 999;
            display: flex;
            align-items: center;

            .title {
                display: flex;
                color: #939393;
                font-size: 14px;
                justify-content: flex-start;
                padding-left: 15px;
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                padding-right: 15px;

                .price {
                    font-size: 14px;
                    color: #333;
                    font-weight: bold;
                    margin-right: 11px;
                }

                .button {
                    width: 115px;
                    height: 36px;
                    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                    -webkit-border-radius: 36px;
                    -moz-border-radius: 36px;
                    border-radius: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    color: #fff;
                }
            }
        }
    }
</style>