<template>
  <div class="formItemV1">
    <div class="formItemV1_body">
      <slot></slot>
    </div>
    <div
      class="formItemV1_error"
      :class="errorClass"
      v-if="validateState === 'error' && showError('message')"
    >
      <slot name="error">
        {{ validateMessage }}
      </slot>
    </div>
  </div>
</template>

<script>
import Emitter from "@U/function/emitter.js";
import schema from "async-validator";
// 去除警告信息
schema.warning = function () {};

/**
 * formItemV1 表单item
 * @description 此组件一般用于表单场景，可以配置Input输入框，Select弹出框，进行表单验证等。
 * @property {Object} prop 表单域model对象的属性名，在使用 validate、resetFields 方法的情况下，该属性是必填的
 * @example <formItemV1></formItemV1>
 */

export default {
  name: "formItemV1",
  mixins: [Emitter],
  inject: {
    formV1: {
      default() {
        return null;
      },
    },
  },
  props: {
    // 绑定的值
    prop: {
      type: String,
      default: "",
    },
    errorClass: {
      type: Array,
      default() {
        return [];
      },
    },
    //子组件名称
    childrenName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      initialValue: "", // 存储的默认值
      validateState: "", // 是否校验成功
      validateMessage: "", // 校验失败的提示语
      // 有错误时的提示方式，message-提示信息，border-如果input设置了边框，变成呈红色，
      errorType: ["message"],
      fieldValue: "", // 获取当前子组件input的输入的值
    };
  },
  watch: {
    validateState(val) {
      this.broadcastInputError();
    },
    // 监听formV1组件的errorType的变化
    "formV1.errorType"(val) {
      this.errorType = val;
      this.broadcastInputError();
    },
  },
  computed: {
    showError() {
      return (type) => {
        // 如果errorType数组中含有none，或者toast提示类型
        if (this.errorType.indexOf("none") >= 0) return false;
        else if (this.errorType.indexOf(type) >= 0) return true;
        else return false;
      };
    },
  },
  // 组件创建完成时，将当前实例保存到u-form中
  mounted() {
    this.parent = this.formV1;
    if (this.parent) {
      // 如果没有传入prop，或者form为空，就不进行校验
      if (this.prop) {
        // 将本实例添加到父组件中
        this.parent.fields.push(this);
        this.errorType = this.parent.errorType;
        // 设置初始值
        this.initialValue = this.fieldValue;
        // 添加表单校验，这里必须要写在$nextTick中，因为form的rules是通过ref手动传入的
        // 不在$nextTick中的话，可能会造成执行此处代码时，父组件还没通过ref把规则给form，导致规则为空
        this.$nextTick(() => {
          this.setRules();
        });
      }
    }
  },
  methods: {
    broadcastInputError() {
      // 子组件发出事件，第三个参数为true或者false，true代表有错误
      if (this.$props.childrenName != "") {
        this.broadcast(
          this.$props.childrenName,
          "on-form-item-error",
          this.validateState === "error" && this.showError("border")
        );
      }
    },
    // 监听formitem下的子组件发出的事件
    setRules() {
      let that = this;
      // console.log(this.$props.prop + "接受到dispatch");
      // blue事件
      this.$on("on-form-item-child-blur", that.onFieldBlur);
      // change事件
      this.$on("on-form-item-child-change", that.onFieldChange);
    },

    // 从form的rules属性中，取出当前formItem的校验规则
    getRules() {
      // 父组件的所有规则
      let rules = this.parent.rules;
      rules = rules ? rules[this.prop] : [];
      // 保证返回的是一个数组形式
      return [].concat(rules || []);
    },

    // blur事件时进行表单校验
    onFieldBlur() {
      this.validation("blur");
    },

    // change事件进行表单校验
    onFieldChange() {
      this.validation("change");
    },

    // 过滤出符合要求的rule规则
    getFilteredRule(triggerType = "") {
      let rules = this.getRules();
      // 整体验证表单时，triggerType为空字符串，此时返回所有规则进行验证
      if (!triggerType) return rules;
      // 历遍判断规则是否有对应的事件，比如blur，change触发等的事件
      // 使用indexOf判断，是因为某些时候设置的验证规则的trigger属性可能为多个，比如['blur','change']
      // 某些场景可能的判断规则，可能不存在trigger属性，故先判断是否存在此属性
      return rules.filter(
        (res) => res.trigger && res.trigger.indexOf(triggerType) !== -1
      );
    },

    // 校验数据
    validation(trigger, callback = () => {}) {
      // 检验之间，先获取需要校验的值
      this.fieldValue = this.parent.model[this.prop];
      // blur和change是否有当前方式的校验规则
      let rules = this.getFilteredRule(trigger);
      // 判断是否有验证规则，如果没有规则，也调用回调方法，否则父组件form会因为
      // 对count变量的统计错误而无法进入上一层的回调
      if (!rules || rules.length === 0) {
        return callback("");
      }
      // 设置当前的装填，标识为校验中
      this.validateState = "validating";
      // 调用async-validator的方法
      let validator = new schema({
        [this.prop]: rules,
      });
      validator.validate(
        {
          [this.prop]: this.fieldValue,
        },
        {
          firstFields: true,
        },
        (errors, fields) => {
          // 记录状态和报错信息
          this.validateState = !errors ? "success" : "error";
          this.validateMessage = errors ? errors[0].message : "";
          // 调用回调方法
          callback(this.validateMessage);
        }
      );
    },

    // 清空当前的formitem
    resetField() {
      this.parent.model[this.prop] = this.initialValue;
      // 设置为`success`状态，只是为了清空错误标记
      this.validateState = "success";
    },
  },
  // 组件销毁前，将实例从form的缓存中移除
  beforeDestroy() {
    // 如果当前没有prop的话表示当前不要进行删除（因为没有注入）
    if (this.parent && this.prop) {
      this.parent.fields.map((item, index) => {
        if (item === this) this.parent.fields.splice(index, 1);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@include b(formItemV1) {
  @include e(error) {
    box-sizing: border-box;
    padding-top: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: red;
  }
}
</style>
